body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F8FA !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-container {
  position: absolute;
  bottom: 0;
  text-align: center;
}

.footer-container h2 {
  font-size: 0.8rem;
  padding: 0 !important;
  margin: 0 !important;
}

.main-page-logo {
  width: 50%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 200px;
}

.qa-page-logo {
  width: 50%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 150px;
}

.main-page-title-wrapper {
  background-color: #FDC400;
  width: 100%;
  padding: 10px;
}

.main-page-title {
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
}

.end-page-title-wrapper {
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}

.end-page-title {
  color: #000;
  font-weight: 600;
  font-size: 1.2rem;
}

.end-page-subtitle {
  color: #000;
  font-weight: 500;
  font-size: 1rem;
}

.main-page-subtitle {
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  font-weight: 700 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.qa-page-title {
  color: #000;
  font-weight: 600;
  text-align: left;
  font-size: 1.1rem;
}

.qa-page-subtitle {
  color: #000;
  font-weight: 500;
  text-align: left;
  font-size: 0.8rem;
}

.social-links {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: solid 0.15rem #DDE5EB;
  border-bottom: solid 0.15rem #DDE5EB;
}

.social-links svg {
  font-size: 4rem;
  color: #FDC400;
  padding: 5px;
  transition: 300ms;
}

.social-links svg:hover {
  transform: scale(1.5);
  color: #000;
}

.social-links-end {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: solid 0.15rem #DDE5EB;
  border-bottom: solid 0.15rem #DDE5EB;
  background-color: #FDC400;
  width: 100%;
  display: flex;
  justify-content: center;
}

.social-links-end svg {
  font-size: 4rem;
  color: #FFF;
  padding: 5px;
  transition: 300ms;
}

.social-links-end svg:hover {
  transform: scale(1.5);
  color: #000;
}

.buttons-main {
  background-color: #FDC400 !important;
  box-shadow: none !important;
  font-size: 1rem !important;
  width: 100%;
  border-radius: 10px !important;
  text-transform: none !important;
  color: #FFF;
  font-weight: 600 !important;
  padding: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms !important;
}

.buttons-main:hover {
  background-color: #000 !important;
  transform: translateY(-10px);
  box-shadow: 0px 5px 5px #0002 !important;
}

.buttons-main svg {
  font-size: 2.2rem;
}

.buttons-main-wpp {
  background-color: #6CC526 !important;
  box-shadow: none !important;
  font-size: 1rem !important;
  width: 100%;
  border-radius: 10px !important;
  text-transform: none !important;
  color: #FFF;
  font-weight: 600 !important;
  padding: 15px !important;
  justify-content: center;
  align-items: center;
  transition: 300ms !important;
}

.buttons-main-wpp:hover {
  background-color: #80ff25 !important;
  transform: translateY(-10px);
  color: #FFF !important;
  box-shadow: 0px 5px 5px #0002 !important;
}

.buttons-main-wpp svg {
  font-size: 2.2rem;
}

.buttons-title {
  font-weight: 600;
  color: #000;
  font-size: 1rem;
}

.buttons-subtitle {
  color: #000;
  font-weight: 300;
  font-size: 0.8rem;
}

.timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.timeline-ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: #e1e1e1 solid 0.2rem;
  background-color: #FFF;
  margin-right: 10px;
  cursor: pointer;
}

.timeline-ball.filled {
  background-color: #FDC400;
  transform: scale(1.5) !important;
  border: none;
}

.timeline-ball.previous {
  border: #FDC400 solid 0.2rem;
  background-color: #FFF;
  transform: scale(1) !important;
}

.timeline-ball:hover {
  transform: scale(1.2);
}

.qa-buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around !important;
  margin-top: 3rem !important;
}

.qa-button-l {
  font-size: 2rem;
  color: #FDC400;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #FFF;
  border: #FDC400 0.15rem solid;
  border-radius: 15px;
  transition: 300ms;
}

.qa-button-l:hover {
  background-color: #FDC400;
  color: #FFF;
  transform: scale(1.2);
}

.qa-button-r {
  font-size: 2rem;
  color: #FFF;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #FDC400;
  border: #FDC400 0.15rem solid;
  border-radius: 15px;
  transition: 300ms;
}

.qa-button-r:hover {
  background-color: #FFF;
  color: #FDC400;
  transform: scale(1.2);
}

.qa-button-next {
  font-size: 2rem;
  color: #FFF;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #FDC400;
  border: #FDC400 0.15rem solid;
  border-radius: 15px;
}

.qa-button-next:hover {
  background-color: #FFF;
  color: #FDC400;
  transform: scale(1.2);
}

.qa-textarea {
  width: 100% !important;
  border-radius: 15px;
}

.question {
  text-align: left !important;
  width: 90%;
  padding-top: 3rem;
  font-size: 1.6rem;
  color: #000;
  font-weight: 700;
  line-height: 28px;
}

.timeline {
  position: relative;
}

.qa-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45svh;
}

.dashboard-item {
  border: solid 0.1rem #FDC400;
  margin: 0rem;
}

.dashboard-total {
  padding: 2rem;
  background-color: #FDC400;
  border-radius: 2rem;
  color: #FFF;
  font-weight: 700;
  text-align: center;
}

.dashboard-total-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-questions-wrapper {
  background-color: #FDC400;
  width: 100%;
  margin: 0 !important;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 2rem 2rem 0rem 0;
}

.dashboard-questions-wrapper-mobile {
  background-color: #FDC400;
  width: 100%;
  margin: 0 !important;
}

.dashboard-answers-wrapper {
  text-align: center;
  padding: 0 !important;
}

.dashboard-answers-container {
  display: flex;
  justify-content: center;
}

.dashboard-button {
  background-color: #FFF;
  margin: 0 0.5rem;
  border-radius: 5rem;
  padding: 0rem 2rem;
  border: 0.2rem solid #FDC400;
  font-weight: bolder;
}

.dashboard-page-num {
  background-color: #FFF;
  margin: 0 0.2rem;
  border-radius: 5rem;
  padding: 0rem 0.5rem;
  border: 0.2rem solid #FDC400;
  font-weight: bolder;
  color: #000;
}

.dashboard-page-num-selected {
  background-color: #FFF;
  margin: 0 0.2rem;
  border-radius: 5rem;
  padding: 0rem 0.5rem;
  border: 0.2rem solid #000;
  font-weight: bolder;
  color: #000;
}

.dashboard-m-answer {
  border: 0.1rem solid #FDC40020;
  margin: 0 !important;
}

.dashboard-m-indicator {
  background-color: #FDC400;
}

.login-container {
  height: 100svh !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FDC400;
}

.login-form {
  height: 100%;
  padding: 2rem;
}

.login-form h1 {
  font-size: 1.8rem;
  font-weight: bolder;
  color: #000;
}

.login-button {
  border: none;
  background-color: #FDC400;
  border-radius: 5rem;
  font-size: 1.8rem;
}

.login-card {
  width: 75%;
  height: 75svh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 764px) {
  .timeline {}

  .mobile {
    display: none !important;
  }
}

@media screen and (max-width: 765px) {
  .desktop {
    display: none !important;
  }
}

@media screen and (max-height: 764px) {
  .footer-container {
    position: relative;
  }
}